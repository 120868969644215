<template>
  <!-- visible默认值是true，靠外部使用v-if控制是否隐藏 -->
  <a-modal
    title="调整账单"
    :width="900"
    :visible="true"
    :confirmLoading="confirmLoading"
    destroyOnClose
    okText="确认生成"
    :okButtonProps="{ props: { disabled: confirmDisabled } }"
    @ok="handleConfirm"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <div style="display: flex; flex-direction: column">
          <div class="new-item-btn">
            <a-button @click="addItem">新增明细</a-button>
          </div>
          <div
            v-bind:key="item.bindKey"
            v-for="item in adjustItems"
            class="adjust-items-row"
          >
            <a-form-item
              label="选择类型"
              class="flex-item"
              :key="`type${item.bindKey}`"
            >
              <a-select
                style="width: 100px"
                v-model="item.type"
                placeholder="请选择"
                :dropdownMatchSelectWidth="false"
              >
                <a-select-option v-for="i in costTypeList" :key="i.id">{{
                  i.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="flex-item" :key="`handle${item.bindKey}`">
              <a-select
                style="width: 80px"
                v-model="item.handleType"
                placeholder="请选择"
              >
                <a-select-option v-for="h in handleTypeList" :key="h.id">{{
                  h.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="账单周期"
              class="flex-item"
              :key="`cycle${item.bindKey}`"
            >
              <a-range-picker style="width: 240px" v-model="item.billCycle" />
            </a-form-item>
            <a-form-item
              label="账单金额"
              class="flex-item"
              :key="`amount${item.bindKey}`"
              :validate-status="item.amountStatus"
              :help="item.helpText"
            >
              <a-input
                v-model="item.amount"
                @change="validateAmount(item)"
                placeholder="请输入"
                class="amount"
              />
            </a-form-item>
            <div :key="`delete${item.bindKey}`">
              <a @click="removeItem(item.bindKey)" class="delete">删除</a>
            </div>
          </div>
          <div class="flex-item total">账单总金额：{{ adjustAmount }}</div>
        </div>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import PropTypes from "ant-design-vue/lib/_util/vue-types";
  
import { negativeDecimalTwoDigit } from "@/utils/regex";

export default {
  name: "bill-cost-adjust-modal",
  props: {
    closeAction: {
      type: Function,
      default: (succeed) => {},
    },
    // 引用账单的Id
    refBillId: {},
  },
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this),
      adjustItems: Array.from({ length: 1 }, () => {
        return {
          type: 1, // 费用类型
          handleType: 1, // 减免/扣除
          billCycle: [null, null], // 账单周期
          amount: null, // 账单金额
          bindKey: Math.random(), // Vue的绑定key
          amountStatus: null, // 账单金额的错误状态
          helpText: null, // 账单金额错误时的显示文本
        };
      }),
      //费用类型
      costTypeList: [],
      // 减免 or 扣除
      handleTypeList: [
        { id: 1, name: "减免" },
        { id: 2, name: "扣除" },
      ],
    };
  },
  computed: {
    confirmDisabled() {
      return (
        this.adjustItems.length === 0 ||
        !!this.adjustItems.find((i) => {
          return (
            i.type == null ||
            i.handleType == null ||
            i.billCycle[0] == null ||
            i.billCycle[1] == null ||
            i.amount == null ||
            i.amountStatus === "error"
          );
        })
      );
    },
    // 账单总金额
    adjustAmount() {
      let total = 0;
      this.adjustItems.forEach((i) => {
        if (i.amount != null && i.handleType === 1) {
          total -= Number(i.amount);
        }
        if (i.amount != null && i.handleType === 2) {
          total += Number(i.amount);
        }
      });
      return total;
    },
  },
  methods: {
    addItem() {
      this.adjustItems.push({
        type: 1,
        handleType: 1,
        billCycle: [null, null],
        amount: null,
        bindKey: Math.random(),
        amountStatus: null,
        helpText: null,
      });
    },
    removeItem(key) {
      let newArray = this.adjustItems.filter((i) => i.bindKey !== key);
      this.adjustItems = Object.assign([], newArray);
    },
    handleConfirm() {
      const data = {
        billDetails: this.adjustItems.map((i) => {
          return {
            amount: i.amount,
            cycleEndTime: new Date(i.billCycle[1]).getTime(),
            cycleStartTime: new Date(i.billCycle[0]).getTime(),
            handleType: i.handleType,
            type: i.type,
          };
        }),
        refBillId: Number(this.refBillId),
      };
      axios({
        url:   this.$baseUrl + "bill/addBillExt",
        withCredentials: true,
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.success) {
          this.closeAction(true);
        } else {
          this.$message.error(res.data.returnMsg);
        }
      });
    },
    handleCancel() {
      this.closeAction();
    },
    validateAmount(item) {
      if (item.amount.match(negativeDecimalTwoDigit)) {
        item.amountStatus = null;
        item.helpText = null;
      } else {
        item.amountStatus = "error";
        item.helpText = "请输入数字";
      }
    },
  },
  created() {
    axios({
      url:   this.$baseUrl + "bill/getAllCostType",
      withCredentials: true,
      method: "GET",
    }).then((res) => {
      if (res.data.success) {
        this.costTypeList = res.data.rows.map(i=> {
          return { id: i.id, name: i.chineseName };
        });
      } else {
        this.$message.error(res.data.returnMsg);
      }
    });
  },
};
</script>

<style scoped >
.flex-item {
  display: flex;
  margin: 0px 10px;
}
.adjust-items-row {
  display: flex;
}
.new-item-btn {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 0px;
}
.amount {
  width: 100px;
}
.delete {
  line-height: 40px;
}
.total {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
}
</style>
